<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Refund reports
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Refund reports
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-on:keyup.enter="searchReport"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>
                
                <v-col cols="12" :md="currentUser.access_type == 'score' ? 6 : 4" class="text-right">
                  <v-btn @click="searchReport()" class="btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">
<!--              <h5>Finance Sales Reports</h5>-->
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table v-if="! loading" class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Candidate name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Approve details</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Refund details</th>
                  <th class="px-3" style="max-width: 170px !important; white-space: pre-wrap;">Score</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Status</th>

                </tr>
                </thead>
                <tbody>
                <template v-if="refund_reports.length != 0">
                  <tr v-for="(item, index) in refund_reports" :key="index">
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                     <span style="max-width: 240px !important; white-space: pre-wrap;" class="font-weight-bolder text-dark-75">
                        {{item.name ? item.name : '-' }}
                      </span>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <div> {{item.candidate_name ? item.candidate_name : '-' }} </div>
                      <a @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key}}
                       </a>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="badge" :class="item.is_approved ? 'badge-success' : 'badge-danger'" >{{item.is_approved ? 'Yes' : 'No'}}</span>
                      <div v-if="item.is_approved && currentUser.access_type =='federal'">
                        <strong> Approved by </strong> {{item.approved_by}} <br> on {{item.approved_date}} <br> <strong>Remarks : </strong> {{item.approved_remarks ? item.approved_remarks : '-'}}
                      </div>
                    </td>
                    
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="badge" :class="item.is_refunded ? 'badge-success' : 'badge-danger'" >{{item.is_refunded ? 'Yes' : 'No'}}</span>
                      <div v-if="item.is_refunded && currentUser.access_type =='federal'">
                        <div> 
                          <strong>
                            Refund amount :
                          </strong>
                          {{item.refund_currency}}{{item.refund_amount}}
                        </div> 
                        <strong> Refunded by </strong> {{item.refunded_by}} <br> on {{item.refund_date}} <br> <strong>Remarks : </strong> <p v-html="item.refund_remarks ? item.refund_remarks : '-'"></p>
                      </div>
                    </td>

                    <td class="px-3" style="max-width: 100px !important; white-space: pre-wrap;">
                      {{ item.score_prefix }}
                    </td> 

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="badge" :class="item.is_closed ? 'badge-success' : 'badge-danger'" >{{item.is_closed ? 'Closed' : 'Not closed'}}</span>
                      <div v-if="item.is_closed && currentUser.access_type =='federal'">
                        <strong> Closed by </strong> {{item.closed_by}} <br> on {{item.closing_date}} <br> <strong>Remarks : </strong> <p v-html="item.closing_remarks ? item.closing_remarks != 'NULL' ? item.closing_remarks : '-' : '-'"></p>
                      </div>
                    </td>

                  </tr>
                </template>
                <tr v-else>
                  <td class="text-center" colspan="6">
                    No report available
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  :disabled="loading"
                  v-if="refund_reports.length > 0"
                  class="pull-right mt-7"
                  @input="getAllRefundReports"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="report_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import ReportService from "@/services/report/ReportService";
import SettingService from "@/services/setting/SettingService";

const setting=new SettingService();
const score=new ScoreService();
const report=new ReportService();

export default {
  data(){
    return{
      loading:false,
      refund_reports:[],
      page:'',
      total:'',
      perPage:'',
      search:{
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        score_id:'',
      },
      scores:[],
      menuStartDate:false,
      menuEndDate:false,
      currentYear:new Date().getFullYear(),
      report_overlay:false,
    }
  },
  methods:{
    getAllRefundReports(){
      this.loading=true;
        report
            .getAllRefundReports(this.search,this.page)
            .then(response => {
             this.refund_reports=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(()=>{
              this.loading=false;
            });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getAllPurchaseTypes(){
      setting
          .getPurchaseTypes()
          .then(response=>{
            for(let key in response.data.purchase_types){
              this.purchase_types.push({value:key, text:response.data.purchase_types[key]})
            }
          })
    },
    searchReport(){
      this.page=1;
      this.getAllRefundReports();
    },
    exportToCsv(){
      report
      .exportRefundToCsv(this.search)
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
  },
  mounted() {
    this.getAllRefundReports();
    this.getAllScore();
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },

  }
}
</script>